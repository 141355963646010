import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
//styles
import styles from "./mobileSidebar.module.css";
//icons
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
//menu items
import { menuItems } from "../menuItems";
export default function MobileSidebar() {
  const [sidebarActive, setSidebarActive] = useState(true);
  return (
    <>
      <aside>
        <section className={styles.sidebar_main_container}>
          <section className={styles.sidebar_main_logo}>
            <Link to="/">
              <img src="https://ik.imagekit.io/z4o3qztf2x/RK-logo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678352070770" />
            </Link>
          </section>
          <section className={styles.sidebar_main_icon}>
            <GiHamburgerMenu onClick={() => setSidebarActive(!sidebarActive)} />
            <span> Menu</span>
          </section>
          <section
            className={`${styles.sidebar_container} ${
              sidebarActive && styles.sidebar_container_active
            }`}
          >
            <section className={styles.sidebar_contents}>
              <ul>
                {menuItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <NavLink to={item.link} key={index}>
                        {item.name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>

              <AiFillCloseCircle
                onClick={() => setSidebarActive(!sidebarActive)}
              />
            </section>
          </section>
        </section>
      </aside>
    </>
  );
}
