import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

// Import Swiper styles
import "swiper/css";

//header
import Header from "./Components/Layouts/Header/Header";
import Footer from "./Components/Layouts/Footer/Footer";

//pages
const Home = lazy(() => import("./Components/Pages/Home/Home"));
const About = lazy(() => import("./Components/Pages/About/About"));
const Plots = lazy(() => import("./Components/Pages/PlotsMain/Plots"));
const Villas = lazy(() => import("./Components/Pages/Villas/Villas"));
const WhyParkAvenue = lazy(() =>
  import("./Components/Pages/WhyParkAvenue/WhyParkAvenue")
);
const Amenities = lazy(() => import("./Components/Pages/Amenities/Amenities"));
const Gallery = lazy(() => import("./Components/Pages/Gallery/Gallery"));
const ContactUs = lazy(() => import("./Components/Pages/ContactUs/Contact"));
const SinglePlots = lazy(() => import("./Components/Pages/Plots/Plots"));
const CompletedProjects = lazy(() =>
  import("./Components/Pages/CompletedProjects/CompletedProjects")
);
const ThanksPage = lazy(() =>
  import("./Components/Pages/ContactUs/ThankYou/ThankYou")
);
function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/plots" element={<Plots />} />
          <Route path="/villas" element={<Villas />} />
          <Route path="/amenities" element={<Amenities />} />
          <Route path="/why-36-park-avenue" element={<WhyParkAvenue />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/thanks-you" element={<ThanksPage />} />
          <Route path="/completed-projects" element={<CompletedProjects />} />
          <Route path="/contact-1" element={<SinglePlots />} />
        </Routes>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
