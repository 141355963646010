import React, { useEffect, useState } from "react";

//styles
import styles from "./header.module.css";

//navlink
import { NavLink } from "react-router-dom";

//menu items
import { menuItems } from "./menuItems";

import MobileSidebar from "./MobileSidebar/MobileSidebar";

// function ListItem(props) {
//   const { item } = props;
//   return (
//     <>
//       <li>
//         <NavLink to="/">{item.name}</NavLink>
//       </li>
//     </>
//   );
// }

export default function Header() {
  const [home, setHome] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    let resize = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return window.removeEventListener("resize", resize);
  }, []);

  useEffect(() => {
    let scroller = window.addEventListener("scroll", () => {
      setHeight(document.documentElement.scrollTop);
    });
    return window.removeEventListener("scroll", scroller);
  }, []);

  return (
    <>
      <header
        className={`${styles.headerContainer} ${
          height > 1 && styles.headerContainerSticky
        } `}
      >
        {width > 600 ? (
          <section className={styles.headerListContainer}>
            <section className={styles.headerList}>
              <nav>
                <ul>
                  {menuItems.map((item, index) => {
                    return (
                      <li key={index}>
                        <NavLink to={item.link}>{item.name}</NavLink>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </section>
          </section>
        ) : (
          <MobileSidebar />
        )}
      </header>
    </>
  );
}
