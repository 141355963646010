import React from "react";

// styles
import styles from "./footer.module.css";

//assets logo
import logo from "../../Assets/Images/rk-logo.png";

//social icons
import { FaFacebook, FaYoutube, FaTwitter, FaLinkedin } from "react-icons/fa";

export default function Footer() {
  return (
    <>
      <footer className={styles.footerContainer}>
        <section className={styles.footerItemsContainer}>
          <article>
            <img src={logo} />
            <div className={styles.socialMedia}>
              <a href="">
                <FaFacebook />
              </a>
              <a href="">
                <FaYoutube />
              </a>
              <a href="">
                <FaTwitter />
              </a>
              <a href="">
                <FaLinkedin />
              </a>
            </div>
          </article>
          <article>
            <h3>RK Developers GROUP</h3>
            <p>
              We are a real estate developer that has been serving our customers
              for many years. Our primary focus is on selling plots and villas,
              and we strive to provide our customers with the best possible
              experience.
            </p>
          </article>
          <article>
            <h3>Corporate Office Address</h3>
            <address>
              Sy No-64/1, Chikkadasarahalli Village, Sarjapur Hobli, Anekal
              Taluk, Bengaluru -562125
            </address>
            <p>080-41314842</p>
            <p>info@rkdeveloper.in </p>
          </article>
        </section>
      </footer>
    </>
  );
}
