export const menuItems = [
  {
    id: 1,
    name: "Why 36 Park Avenue",
    link: "/why-36-park-avenue",
  },
  {
    id: 2,
    name: "Home",
    link: "/",
  },
  {
    id: 3,
    name: "About Us",
    link: "/about-us",
  },
  {
    id: 3,
    name: "Completed Projects",
    link: "/completed-projects",
  },
  // {
  //   id: 4,
  //   name: "Villas",
  //   link: "/villas",
  // },
  // {
  //   id: 5,
  //   name: "Plots",
  //   link: "/plots",
  // },
  {
    id: 6,
    name: "Amenities",
    link: "/amenities",
  },
  {
    id: 7,
    name: "Gallery",
    link: "/gallery",
  },
  // {
  //   id: 8,
  //   name: "Location",
  //   link: "#",
  // },
  {
    id: 9,
    name: "Contact",
    link: "/contact",
  },
];
